import React, { useState, useEffect } from 'react';
import LoadingAnimation from './LoadingAnimation';  // Import the LoadingAnimation component

const TailoredMindfulness = () => {
  const [text, setText] = useState('');
  const [modelResponse, setModelResponse] = useState('');
  const [audioSrc, setAudioSrc] = useState('');  // State for audio source
  const [isLoading, setIsLoading] = useState(false);  // State for loading
  const [duration, setDuration] = useState(8);  // State for session duration with a default value
  const [userInfo, setUserInfo] = useState(null);  // State for user info (null when not logged in)

  // Suggested topics for the meditation
  const suggestions = [ "Sleep", "Gratitude", "Focus", "Stress Relief", "Stronger Connections", "Body-scan" ];

  // Check if the user is logged in (for example, you can fetch user info from the server)
  useEffect(() => {
    const fetchUserInfo = async () => {
      const response = await fetch('/api/get-user-info');
      const data = await response.json();
      if (data.username) {
        setUserInfo(data);  // Set user info if logged in
      }
    };

    fetchUserInfo();
  }, []);

  const handleInputChange = (e) => {
    setText(e.target.value);
  };

  const handleSuggestionClick = (suggestion) => {
    setText(suggestion);  // Update input with the suggestion text
  };

  const handleDurationChange = async (e) => {
    setDuration(e.target.value);
    await fetch(`/update-session-duration/?duration=${encodeURIComponent(e.target.value)}`);
  };

  const handlePlayButtonClick = async () => {
    setIsLoading(true);  // Start loading
    const response = await fetch(`/get-text-response?text=${encodeURIComponent(text)}&duration=${encodeURIComponent(duration)}`);
    const data = await response.json();
    setModelResponse(data.response);

    // Set the audio source after getting the model response
    const audioUrl = `/stream-silence-mp3?text=${encodeURIComponent(data.response)}&duration=${encodeURIComponent(duration)}`;
    setAudioSrc(audioUrl);

    setIsLoading(false);  // Stop loading
  };

  const handleGoogleLogin = () => {
    window.location.href = '/accounts/google/login/';
  };

  return (
    <div style={styles.container}>
      <div style={styles.topRight}>
        {userInfo ? (
          <div style={styles.userContainer}>
            <img src={userInfo.avatarUrl} alt="User Avatar" style={styles.avatar} />
            <span>{userInfo.username}</span>
          </div>
        ) : (
          <button onClick={handleGoogleLogin} style={styles.signInButton}>
            Sign in 
          </button>
        )}
      </div>

      <h1 style={styles.title}>Tailored Meditation Session</h1>

      <input
        type="text"
        placeholder="Enter topic for the meditation session"
        value={text}
        onChange={handleInputChange}
        style={styles.input}
      />

      {/* Suggestion Buttons */}
      <div style={styles.suggestionContainer}>
        {suggestions.map((suggestion, index) => (
          <button
            key={index}
            style={styles.suggestionButton}
            onClick={() => handleSuggestionClick(suggestion)}
          >
            {suggestion}
          </button>
        ))}
      </div>
      
      <div style={styles.sliderContainer}>
        <label style={styles.label}>Session Duration: {duration} minutes</label>
        <input
          type="range"
          min="1"
          max="60"
          value={duration}
          onChange={handleDurationChange}
          style={styles.slider}
        />
      </div>  
      
      <button onClick={handlePlayButtonClick} style={styles.button}>
        Generate Meditation
      </button>
      
      {isLoading ? (
        <LoadingAnimation />  // Show loading animation
      ) : (
        modelResponse && audioSrc && (  // Show media player instead of response container
          <audio controls style={styles.audio}>
            <source src={audioSrc} type="audio/wav" />
            Your browser does not support the audio element.
          </audio>
        )
      )}
    </div>
  );
};

// Simple styles object for the components
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f5f5f5',
    fontFamily: 'Arial, sans-serif',
  },
  topRight: {
    position: 'absolute',
    top: '20px',
    right: '20px',
  },
  userContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    marginRight: '8px',
  },
  signInButton: {
    padding: '8px 16px',
    fontSize: '0.9rem',
    backgroundColor: '#4285F4',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  title: {
    fontSize: '2rem',
    marginBottom: '20px',
    color: '#333',
  },
 input: {
  padding: '10px',
  fontSize: '1rem',
  marginBottom: '20px', // Increased from 10px to 20px
  width: '80%',
  maxWidth: '400px',
  border: '1px solid #ccc',
  borderRadius: '4px',
},
suggestionContainer: {
  marginTop: '20px', // Increased from 10px to 20px
  display: 'flex',
  justifyContent: 'center',
  gap: '15px', // Increased the gap between buttons
},
sliderContainer: {
  marginTop: '30px', // Added margin to push it further from the suggestions
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '30px', // Increased from 20px to 30px
  width: '80%',
  maxWidth: '400px',
}, 
 suggestionButton: {
    padding: '8px 12px',
    fontSize: '0.9rem',
    borderRadius: '50%',
    border: '1px solid #ccc',
    backgroundColor: '#f0f0f0',
    cursor: 'pointer',
  },
  label: {
    marginBottom: '10px',
    fontSize: '1rem',
    color: '#333',
  },
  slider: {
    width: '100%',
  },
  button: {
    padding: '10px 20px',
    fontSize: '1rem',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginBottom: '20px',
  },
  audio: {
    width: '80%',
    maxWidth: '400px',
  },
};

export default TailoredMindfulness;

